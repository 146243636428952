import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import _ from 'lodash'
import _t from '../translate'
import device from '../device'
import JourneySearchOriginDestination from './journey-search/origin-destination'
import JourneySearchOutboundInboundDate from './journey-search/outbound-inbound-date'
import JourneyOptions from './journey-search/journey-options'
import PassengerSpecifications from './journey-search/passenger-specifications'
import Button from '../element/button'
import TextLabel from '../element/text-label'
import Icon from '../element/icon'
import journeySearch from './journey-search'
import OuiplusAd from './ouiplus-ad'
import CalendarStore from '../reflux/stores/calendar-store'
import AffiliateStore from '../reflux/stores/affiliate-store'
import StationsStore from '../reflux/stores/stations-store'
import actions from '../reflux/actions'
import {mapPassengers} from '../misc/helpers'
import journeySearchManager from '../data/journey-search-manager'

class JourneySearchContainer extends Component {
    static propTypes = {
        stations: PropTypes.array,
        journeySelection: PropTypes.object,
        buttonLoading: PropTypes.bool,
        onOriginSelected: PropTypes.func,
        onDestinationSelected: PropTypes.func,
        onSwapOriginAndDestination: PropTypes.func,
        onDatesSelected: PropTypes.func,
        onPassengersSelected: PropTypes.func,
        onJourneySubmit: PropTypes.func,
        isJourneySelectionValid: PropTypes.func,
        isChildUnaccompanied: PropTypes.func,
        showCollapsed: PropTypes.bool,
        hasOffer: PropTypes.bool.isRequired,
        location: PropTypes.object
    }

    onModifySearchClick () {
        actions.clearOffer()
    }

    constructor (...args) {
        super(...args)
        this.state = {
            showGroupTravelDialog: true,
            showInboundDate: this.props.journeySelection.inboundDate instanceof Date,
            lastSelectedInboundDate: _.get(
                this.props,
                'journeySelection.inboundDate',
                _.get(this.props, 'defaultInboundDate')
            )
        }

        this._renderSearchForm = this._renderSearchForm.bind(this)
        this.getCurrency = this.getCurrency.bind(this)
        this.onJourneySubmit = this.onJourneySubmit.bind(this)
        this._getOffer = this._getOffer.bind(this)
        this.onChangeJourneyOptions = this.onChangeJourneyOptions.bind(this)

        this.onDateSelected = this.onDateSelected.bind(this)
        this.onPassengersSelected = this.onPassengersSelected.bind(this)
        this.renderChildCannotTravelAloneError = this.renderChildCannotTravelAloneError.bind(this)
        this.isJourneySelectionValid = this.isJourneySelectionValid.bind(this)
    }

    componentWillMount () {
        const query = this.props.location.query
        if (query.load === '1') {
            this.history.replaceState(null, this.props.location.pathname, _.omit(query, 'load'))
            this._getOffer(this.getCurrency())
        }
        this.onPassengersSelected(this.props.journeySelection.passengers)
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.journeySelection.inboundDate instanceof Date) {
            this.setState({
                showInboundDate: true
            })
        }
    }

    render () {
        return this.props.showCollapsed ? this._renderCollapsedBox() : this._renderContent()
    }

    _renderCollapsedBox () {
        return (
            <div className='journey-search-modify'>
                <Button type='button'
                    className='button small default modify-search'
                    onClick={this.onModifySearchClick}>
                    <Icon className='search icon xsmall align-left' type='search' />
                    <TextLabel text={_t.formatIntlMessage('journey-search.modify-search-button.title')} />
                </Button>
            </div>
        )
    }

    _renderContent () {
        return (
            <div className='journey-search-container'>
                {this._renderSearchForm()}
                {this._renderOuiplusAd()}
            </div>
        )
    }

    _renderOuiplusAd () {
        return AffiliateStore.getAffiliateCode() && device.isDesktop() ? (
            <OuiplusAd />
        ) : null
    }

    _renderSearchForm () {
        const labelPrefix = this.props.hasOffer ? 'journey-search.modify-search' : 'journey-search.initial-search'

        return (
            <div className='journey-search'>
                <div className='panel'>
                    <header>
                        <h1>{_t.getIntlMessage(`${labelPrefix}.title`)}</h1>
                    </header>
                    <div className='content'>
                        <form noValidate>
                            <JourneySearchOriginDestination
                                stations={this.props.stations}
                                origin={this.props.journeySelection.origin}
                                destination={this.props.journeySelection.destination}
                                onOriginSelected={this.props.onOriginSelected}
                                onDestinationSelected={this.props.onDestinationSelected}
                                onSwapOriginAndDestination={this.props.onSwapOriginAndDestination}
                            />

                            <JourneyOptions hasInboundDate={this.state.showInboundDate}
                                onChange={this.onChangeJourneyOptions} />

                            <JourneySearchOutboundInboundDate
                                outboundDate={this.props.journeySelection.outboundDate}
                                inboundDate={this.props.journeySelection.inboundDate}
                                showInboundDate={this.state.showInboundDate}
                                onChange={this.onDateSelected}
                                numberOfMonths={device.isDesktopOrTablet() ? 2 : 1}
                                dateFormat='d M yy'
                            />

                            <PassengerSpecifications
                                passengers={mapPassengers(this.props.journeySelection.passengers)}
                                onChange={this.onPassengersSelected}
                            />
                            {this.renderChildCannotTravelAloneError()}
                            <div className='submit-journey-search'>
                                <Button
                                    id='submit-journey-search'
                                    type='submit'
                                    className='button search primary'
                                    title={_t.getIntlMessage(`${labelPrefix}.button.search-title`)}
                                    disabled={!this.isJourneySelectionValid()}
                                    onClick={this.onJourneySubmit}
                                    loading={this.props.buttonLoading}
                                >
                                    <TextLabel text={_t.getIntlMessage(`${labelPrefix}.button.search-title`)} />
                                    <Icon className='chevron-right icon xsmall align-right'
                                        type='chevron-right' />
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )
    }

    getCurrency () {
        const originCountryCode = StationsStore.getStationCountryCode(this.props.journeySelection.origin)

        return journeySearchManager.getCountryCurrency(originCountryCode)
    }

    onJourneySubmit (event) {
        event.preventDefault()

        this._getOffer(this.getCurrency())
    }

    _getOffer (currency) {
        if (this.isJourneySelectionValid()) {
            const journeySelection = _.merge(this.props.journeySelection, {
                currency: currency
            })
            actions.getOffer(journeySelection)
            actions.getCalendar(CalendarStore.createDateSwitcherRequestData(journeySelection))
        }
    }

    onChangeJourneyOptions (event) {
        const isReturnTrip = event.target.value === '1'
        this.setState({
            showInboundDate: isReturnTrip
        }, () => this.props.onDatesSelected({
            inboundDate: isReturnTrip ? this.state.lastSelectedInboundDate : null,
            outboundDate: this.props.journeySelection.outboundDate
        }))
    }

    onDateSelected (event) {
        if ('inboundDate' in event && event.inboundDate instanceof Date) {
            this.setState({lastSelectedInboundDate: event.inboundDate}, () => this.props.onDatesSelected(event))
        } else {
            this.props.onDatesSelected(event)
        }
    }

    onPassengersSelected (passengers) {
        this.props.onPassengersSelected(passengers)
    }

    renderChildCannotTravelAloneError () {
        return this.props.isChildUnaccompanied()
            ? <div
                className='input-feedback state--is-invalid'>{_t.getIntlMessage('journey-search.child-cannot-travel-alone')}</div>
            : null
    }

    isJourneySelectionValid () {
        let result = this.props.isJourneySelectionValid()

        if (this.state.showInboundDate) {
            result = result && this.props.journeySelection.inboundDate
        }

        return result
    }
}

export default withRouter(journeySearch(JourneySearchContainer))
