import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import groupBy from 'lodash/groupBy'
import {
    PRODUCT_CODE_SEAT_SELECTION,
    PRODUCT_CODE_SEAT_SELECTION_PLUS
} from '../../../constants'
import _t from '../../../translate'
import Loader from '../../../element/loader'
import SeatSelector from '../../../components/seat-selector/seat-selector'
import BookingModel from '../../../models/booking-model'
import SegmentModel from '../../../models/segment-model'
import BookingStore from '../../../reflux/stores/booking-store'
import ProductsStore from '../../../reflux/stores/products-store'
import CarriageLayoutsStore from '../../../reflux/stores/carriage-layouts-store'
import actions from '../../../reflux/actions'
import {
    hasManualSeatPlusProperty,
    isSeatProductCode,
    seatSelectionAvailableSeats
} from '../../../misc/helpers'
import {
    connectState,
    getState
} from '../../../reflux/bridge/connect-state'
import {
    seatSelectionPassengerFaresProductSearchSelector,
    seatSelectionPlusPassengerFaresProductSearchSelector,
    seatsWithPricesSelector
} from '../../../models/selectors/components/booking/seat-selection'
import createReactClass from 'create-react-class'

const SeatSelectorContainer = createReactClass({
    displayName: 'MyS3SeatSelectorLayoutContainer',

    mixins: [
        Reflux.connectFilter(CarriageLayoutsStore, 'carriageLayout', data => data.carriageLayout),
        Reflux.listenTo(BookingStore, 'updateBooking')
    ],

    propTypes: {
        legId: PropTypes.string,
        readOnly: PropTypes.bool,
        editOnly: PropTypes.bool,
        passengerFaresSeat: PropTypes.array,
        passengerFaresSeatPlus: PropTypes.array,
        returnUrl: PropTypes.string,
        router: PropTypes.object
    },

    getDefaultProps () {
        return {
            readOnly: false,
            editOnly: false
        }
    },

    getInitialState () {
        return {
            loading: false,
            selectedSeats: [],
            passengerFares: [],
            tariffCode: '',
            ...this._getBookingState(BookingStore.getBookingModel())
        }
    },

    componentWillMount () {
        const booking = this.state.booking
        const travels = this._getTravels()
        this._getDefaultSelection(travels)
        const {segments, passengers} = booking.segmentsAndPassengersForProducts

        this.setState({loading: true})
        actions.getProducts({segments, passengers, currency: booking.currency})

        const selectedSeats = this.props.readOnly
            ? [
                ...booking.tariffSegmentCollection.autoAssignedSeats
                    .filter(seat => booking.seatSelectionOptions().value()
                        .some(seatOption => seatOption.leg.id === seat.leg_id && seatOption.leg.show_seats_threshold)
                    ),
                ...booking.tariffSegmentCollection.selectedManualSelectedSeats
            ]
            : booking.tariffSegmentCollection.selectedManualSelectedSeats
        this.setState({
            selectedSeats
        })
        this._getAvailableSections(booking).then(
            () => this._getCarriage(this.state.selected.legId)
        )
    },

    updateBooking (data) {
        if (!data.loading) {
            const booking = BookingModel.create(data.booking)
            this.setState(this._getBookingState(booking))
        }
    },

    _getDefaultSelection (travels) {
        let segmentIndex = 1
        const travelDirection = (travels.outbound || travels.inbound)
        const segment = this.props.legId
            ? Object.keys(travels)
                .reduce((selectedLeg, direction) =>
                    travels[direction].find((travel, index) => {
                        if (travel.leg.id !== this.props.legId) {
                            return false
                        }
                        segmentIndex = index
                        return true
                    }) || selectedLeg
                    , {})
            : travelDirection[0]

        this.setState(state => ({
            collapsed: {
                ...state.collapsed,
                [segment.direction]: true
            },
            selected: {
                direction: segment.direction,
                leg: segmentIndex + 1,
                legId: segment.leg.id,
                busNumber: segmentIndex + 1
            }
        }))
    },

    _getBookingState (booking) {
        const newState = {
            booking,
            selectedSeats: booking.tariffSegmentCollection.selectedManualSelectedSeats || [],
            travels: booking.seatSelectionOptions().groupBy('direction').value()
        }

        if (booking && !(this.state && this.state.selectedPassenger)) {
            newState.selectedPassenger = booking.passengers.first()
        }

        return newState
    },

    _getNextSelectionPassenger (selectedSeats, legId) {
        return this.state.booking.passengers.find(
            passenger => !selectedSeats.some(
                seat => seat.passenger_id === passenger.id && seat.leg_id === legId
            )
        )
    },

    _getSelectedPassenger (selectedSeat) {
        return this.state.booking.passengers.find(passenger => selectedSeat.passenger_id === passenger.id)
    },

    _filterSelectedSeatsByPassengerAndLegId (passenger, legId) {
        return this.state.selectedSeats.filter(
            selectedSeat => !(
                selectedSeat.passenger_id === passenger.id &&
                selectedSeat.leg_id === legId
            )
        )
    },

    _getCarriage (legId) {
        const journeySegment = this.state.booking.getJourneySegmentById(legId)
        const segmentAvailability = {
            serviceName: journeySegment.service_name,
            fromStationUIC: journeySegment.departure_station && journeySegment.departure_station._u_i_c_station_code,
            toStationUIC: journeySegment.arrival_station && journeySegment.arrival_station._u_i_c_station_code,
            travelDate: journeySegment.travel_date
        }
        actions.getCarriageLayouts(segmentAvailability)
    },

    _getTravels () {
        const seatSelectionOptions = this.state.booking.seatSelectionOptions().value()
        return groupBy(seatSelectionOptions, 'direction')
    },

    _getSelectedSeatsForCurrentLeg () {
        return this.state.selectedSeats.filter(seat => seat.leg_id === this.state.selected.legId)
    },

    async _getAvailableSections (booking) {
        const numberOfPassengers = booking.passengers.size()
        const availableSections = []
        const collapsed = {}
        for (const bookingOption of booking.seatSelectionOptions().value()) {
            const leg = bookingOption.leg || {}
            const carriageLayout = await actions.getCarriageLayouts({
                serviceName: leg.service_name,
                fromStationUIC: leg.departure_station && leg.departure_station._u_i_c_station_code,
                toStationUIC: leg.arrival_station && leg.arrival_station._u_i_c_station_code,
                travelDate: leg.service_schedule_date && leg.service_schedule_date.substr(0, 10)
            })
            const availableSeats = seatSelectionAvailableSeats(carriageLayout)

            if (numberOfPassengers <= availableSeats && booking.canSelectSeatByLeg(leg.id)) {
                availableSections.push(leg.id)
            } else {
                collapsed[bookingOption.direction] = true
            }
        }

        CarriageLayoutsStore.resetData()
        return this.setState(state => ({
            collapsed: {
                ...state.collapsed,
                ...collapsed
            },
            availableSections,
            loading: false
        }))
    },

    async handleClickNext (event) {
        event && event.preventDefault()
        event && event.stopPropagation()

        const seatsWithPrice = seatsWithPricesSelector(this.state.selectedSeats)(getState())
        seatsWithPrice.forEach(seat => {
            actions.trackEvent('SeatOption', {basket_seat_option_action: 'booked', basket_seat_price: seat.price})
        })

        this.setState({loading: true})
        const confirmedSeats = this.state.selectedSeats.filter(seat => !seat.provisional)
        const segments = []
        const seats = []
        const deleteItemRefs = []
        confirmedSeats.forEach(seat => {
            const tariffSegment = new SegmentModel(this.state.booking.getTariffSegmentDataById(seat.leg_id).first().tariffSegment)
            const requiredProduct = tariffSegment.requiredProductsWithoutCancelled.find(product => product.passenger_id === seat.passenger_id)

            seats.push({
                carriage: seat.carriage_number,
                number: seat.seat_number,
                item_ref: requiredProduct.item_ref
            })

            const tariffSegmentAdditionalProduct = this.state.booking.tariffSegments.find(segment =>
                segment.additional_products.length &&
                segment.departure_station &&
                segment.arrival_station &&
                tariffSegment.departureStation &&
                tariffSegment.arrivalStation &&
                segment.departure_station._u_i_c_station_code === tariffSegment.departureStation._u_i_c_station_code &&
                segment.arrival_station._u_i_c_station_code === tariffSegment.arrivalStation._u_i_c_station_code &&
                segment.validity_start_date === tariffSegment.startValidityDate &&
                segment.additional_products.some(product =>
                    !product.cancelled &&
                    isSeatProductCode(product.code) &&
                    product.passenger_id === seat.passenger_id
                )
            )

            if (tariffSegmentAdditionalProduct) {
                const selectedSeatProduct = tariffSegmentAdditionalProduct.additional_products.find(product =>
                    !product.cancelled &&
                    isSeatProductCode(product.code) &&
                    product.passenger_id === seat.passenger_id
                )

                if (selectedSeatProduct.code === seat.seat_product_code) {
                    return // selected seat already has a product
                }

                deleteItemRefs.push(selectedSeatProduct.item_ref)
            }

            const fares = seat.seat_product_code === PRODUCT_CODE_SEAT_SELECTION_PLUS
                ? this.props.passengerFaresSeatPlus
                : this.props.passengerFaresSeat
            const fare = (fares || []).find(passengerFare => passengerFare.passenger_ref === seat.passenger_id)
            const item = {
                passenger_id: seat.passenger_id,
                tariff_code: fare && fare.tariffCode
            }
            const segment = segments.find(_segment => _segment.id === tariffSegment.id)
            if (segment) {
                segment.items.push(item)
            } else {
                segments.push({
                    id: tariffSegment.id,
                    origin: tariffSegment.departureStation && tariffSegment.departureStation._u_i_c_station_code,
                    destination: tariffSegment.arrivalStation && tariffSegment.arrivalStation._u_i_c_station_code,
                    start_validity_date: tariffSegment.startValidityDate,
                    direction: tariffSegment.direction,
                    service_name: tariffSegment.validityService,
                    service_identifier: tariffSegment.serviceIdentifier || `${tariffSegment.validityService}|${tariffSegment.startValidityDate}`,
                    items: [item]
                })
            }
        })

        if (deleteItemRefs.length) {
            await actions.deleteItems(this.state.booking.bookingNumber, {'item_refs': deleteItemRefs})
        }

        if (segments.length) {
            await actions.patchBooking(this.state.booking.bookingNumber, {
                segments,
                currency: this.state.booking.currency
            })
        }

        if (seats.length) {
            await actions.updateSeats(this.state.booking.bookingNumber, seats)
        }

        if (!this.state.booking.requiresPayment) {
            await actions.confirmBooking(this.state.booking.bookingNumber)
            this.props.router.push(`/${_t.getLocales()}/mys3/seat/confirmation?s3_status=success`)
        } else {
            this.props.router.push(`/${_t.getLocales()}/mys3/seat/payment`)
        }
    },

    async handleClickPrevious (event) {
        if (this.props.returnUrl) {
            event && event.preventDefault()
            event && event.stopPropagation()

            this.state.booking.provisionalProducts.value().length && await actions.revertBooking()
            this.props.router.push(`/${_t.getLocales()}${this.props.returnUrl}`)
        }
    },

    handleCollapseSection (direction) {
        const collapsed = this.state.collapsed
        collapsed[direction] = !this.state.collapsed[direction]
        this.setState({
            collapsed
        })
    },

    handleSelectSeat (seat) {
        if (seat.provisional === false && !this.props.editOnly) {
            return
        }

        const selectedSeats = this._filterSelectedSeatsByPassengerAndLegId(
            this.state.selectedPassenger,
            this.state.selected.legId
        )

        selectedSeats.push({
            passenger_id: this.state.selectedPassenger.id,
            leg_id: this.state.selected.legId,
            seat_number: seat.seat_number,
            carriage_number: this.state.carriageLayout ? this.state.carriageLayout.carriages[0].carriage_number : null,
            seat_product_code: hasManualSeatPlusProperty(seat)
                ? PRODUCT_CODE_SEAT_SELECTION_PLUS
                : PRODUCT_CODE_SEAT_SELECTION
        })

        let direction = this.state.selected.direction
        let legId = this.state.selected.legId
        let nextPassenger = this._getNextSelectionPassenger(selectedSeats, this.state.selected.legId)
        if (!nextPassenger) {
            for (const option of this.state.booking.seatSelectionOptions().value()) {
                if (this.state.availableSections.includes(option.leg.id)) {
                    nextPassenger = this._getNextSelectionPassenger(selectedSeats, option.leg.id)
                    if (nextPassenger) {
                        direction = option.direction
                        legId = option.leg.id
                        break
                    }
                }
            }
        }

        this.setState(
            {selectedSeats},
            () => nextPassenger && this.handleSelectPassenger(nextPassenger, direction, legId)
        )
    },

    handleRemoveSeatBySeat (selectedSeat) {
        const seat = this.state.selectedSeats.find(seat => seat.seat_number === selectedSeat.seat_number)
        const passenger = this._getSelectedPassenger(seat)
        this.handleSelectPassenger(passenger, this.state.selected.direction, this.state.selected.legId)
        this.setState({
            selectedSeats: this._filterSelectedSeatsByPassengerAndLegId(passenger, this.state.selected.legId)
        })
    },

    handleRemoveSeatByPassenger (passenger, legId) {
        this.setState({
            selectedSeats: this._filterSelectedSeatsByPassengerAndLegId(passenger, legId)
        })
    },

    handleSelectPassenger (passenger, direction, legId) {
        if (this.props.editOnly && !this.state.booking.tariffSegmentCollection.selectedManualSelectedSeats.some(seat => seat.leg_id === legId && seat.passenger_id === passenger.id)) {
            return
        }

        if (this.state.selected.legId !== legId) {
            this._getCarriage(legId)
        }

        const legIndex = this.state.travels[direction].findIndex(segment => segment.leg.id === legId)

        this.setState(state => ({
            collapsed: {
                ...state.collapsed,
                [direction]: true
            },
            selectedPassenger: passenger,
            selected: {
                direction,
                leg: legIndex + 1,
                legId,
                busNumber: legIndex + 1
            }
        }))
    },

    render () {
        return !this.state.loading && this.state.carriageLayout && this.state.availableSections
            ? <SeatSelector
                availableSections={this.state.availableSections}
                travels={this.state.travels}
                passengers={this.state.booking.passengers.value()}
                onSelectPassenger={this.handleSelectPassenger}
                onSelectSeat={this.handleSelectSeat}
                onRemoveSeatBySeat={this.handleRemoveSeatBySeat}
                onRemoveSeatByPassenger={this.handleRemoveSeatByPassenger}
                onClickNext={this.handleClickNext}
                onClickPrevious={this.handleClickPrevious}
                selected={this.state.selected}
                selectedPassenger={this.state.selectedPassenger || {}}
                selectedCarriage={this.state.carriageLayout.carriages[0] || {}}
                selectedSeats={this.state.selectedSeats || []}
                selectedSeatsCurrentLeg={this._getSelectedSeatsForCurrentLeg()}
                collapsed={this.state.collapsed}
                collapseSection={this.handleCollapseSection}
                readOnly={this.props.readOnly}
                editOnly={this.props.editOnly}
            />
            : <Loader blocking />
    }
})

const mapPropsToProps = () => {
    const state = getState()

    return {
        passengerFaresSeat: seatSelectionPassengerFaresProductSearchSelector(state),
        passengerFaresSeatPlus: seatSelectionPlusPassengerFaresProductSearchSelector(state)
    }
}

export default withRouter(connectState(mapPropsToProps, [ProductsStore])(SeatSelectorContainer))
