import React from 'react'
import Reflux from 'reflux'
import endsWith from 'lodash/endsWith'
import groupBy from 'lodash/groupBy'
import kebabCase from 'lodash/kebabCase'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import _t from '../../../translate'
import AccessManager from '../../../data/access-manager'
import Tabs from '../../../element/tabs'
import CreditCard from './payment-options/creditcard'
import CreditCardAdyen from './payment-options/creditcard-adyen'
import Ideal from './payment-options/ideal'
import Voucher from './payment-options/voucher'
import CashInvoice from './payment-options/cash-invoice'
import CmsBlockContent from '../../cms/cms-block-content'
import actions from '../../../reflux/actions'
import PaymentStore from '../../../reflux/stores/payment-store'
import PaymentMethodsStore from '../../../reflux/stores/payment-methods-store'
import BookingStore from '../../../reflux/stores/booking-store'
import BookingModel from '../../../models/booking-model'
import device from '../../../device'
import Select from '../../../element/form/simple-select-field'
import {
    PAYMENT_METHOD_FLOW_TYPE_IDEAL,
    PAYMENT_METHOD_FLOW_TYPE_BANCONTACT,
    PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED,
    PAYMENT_METHOD_FLOW_TYPE_VOUCHER,
    PAYMENT_METHOD_FLOW_TYPE_INVOICE,
    PAYMENT_METHOD_CODE_VOUCHER,
    CURRENCY_EUR,
    PAYMENT_METHOD_FLOW_TYPE_SOFORT,
    PAYMENT_METHOD_FLOW_TYPE_PAYPAL,
    PAYMENT_FORM_ADYEN_CREDITCARD_ENCRYPTED,
    PAYMENT_FORM_ADYEN_IDEAL,
    PAYMENT_FORM_ADYEN_PAYPAL,
    PAYMENT_FORM_ADYEN_SOFORT,
    PAYMENT_FORM_CASH,
    PAYMENT_FORM_VOUCHER,
    PAYMENT_FORM_WPAY_BANCONTACT,
    PAYMENT_FORM_WPAY_CREDITCARD_ENCRYPTED,
    PAYMENT_METHOD_FLOW_TYPE_DEFAULT,
    PAYMENT_PROVIDER_WORLDPAY,
    PAYMENT_PROVIDER_ADYEN,
} from '../../../constants'
import Redirect from './payment-options/redirect'
import createReactClass from 'create-react-class'

// Adyen payment methods end with _{REGION_CODE}. For example CC3DS_BE is for adyen, while CC3DS is for Worldpay.
// The last part of the language code of the user, needs to match the last part part of the payment method code.
const filterBlaBlabusPaymentMethods = method => endsWith(method.code, `_${_t.getRegion().toUpperCase()}`)

const filtersPerFlowType = {
    [PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED]: methods => {
        // Adyen only for Blablabus languages
        const languageMethods = methods.filter(filterBlaBlabusPaymentMethods)
        if (languageMethods.length) {
            // Adyen payment methods available
            return languageMethods
        }

        return []
    },
    [PAYMENT_METHOD_FLOW_TYPE_IDEAL]: (methods, {isEuro}) => isEuro ? methods.filter(filterBlaBlabusPaymentMethods) : [], // only Euro
    [PAYMENT_METHOD_FLOW_TYPE_BANCONTACT]: (methods, {isEuro}) => isEuro ? methods : [], // only Euro
    [PAYMENT_METHOD_FLOW_TYPE_INVOICE]: methods => methods,
    [PAYMENT_METHOD_FLOW_TYPE_PAYPAL]: methods => methods.filter(filterBlaBlabusPaymentMethods),
    [PAYMENT_METHOD_FLOW_TYPE_SOFORT]: methods => methods.filter(filterBlaBlabusPaymentMethods),
    [PAYMENT_METHOD_FLOW_TYPE_VOUCHER]: (methods, {isVoucherAvailable}) => isVoucherAvailable ? methods : [],
    [PAYMENT_METHOD_FLOW_TYPE_DEFAULT]: methods => methods // Default tab when an unknown flowtype is configured
}

export default createReactClass({

    mixins: [
        Reflux.connect(PaymentStore, 'payment'),
        Reflux.listenTo(BookingStore, 'updateBooking'),
        Reflux.listenTo(PaymentMethodsStore, 'updatePaymentMethods'),
        Reflux.connectFilter(PaymentMethodsStore, 'paymentMethodsLoading', data => data.loading)
    ],

    language: _t.getLocales(),

    getInitialState () {
        return {
            booking: BookingStore.getBookingModel(),
            disabled: this._isPaymentDisabled(),
            methods: null,
            paymentOptions: null
        }
    },

    componentDidMount () {
        if (!this.state.availableMethods && this.state.booking) {
            actions.getPaymentMethods(BookingStore.getBookingNumber())
        }
    },

    componentDidUpdate () {
        const language = _t.getLocales()
        if (this.language !== language) {
            this.language = language
            this._processPaymentMethods(this.state.booking, this.state.availableMethods)
        }
    },

    updateBooking (data) {
        const disabled = this._isPaymentDisabled()
        const resetMethod = disabled && !this.state.disabled
        const booking = BookingModel.create(data.booking)

        this.setState(
            {booking, disabled},
            () => {
                this._processPaymentMethods(booking, this.state.availableMethods)
                resetMethod && this.handleSelectMethod(null)
            }
        )
    },

    _isPaymentDisabled () {
        return !BookingStore.requiresPaymentMethod() && !AccessManager.isCrmUser()
    },

    updatePaymentMethods (data) {
        if (!data.loading) {
            const availableMethods = (data.availableMethods || [])
                .concat({
                    code: PAYMENT_METHOD_CODE_VOUCHER,
                    flow_type: PAYMENT_METHOD_FLOW_TYPE_VOUCHER
                })

            this.setState(
                {availableMethods},
                () => this._processPaymentMethods(this.state.booking, availableMethods)
            )
        }
    },

    _processPaymentMethods (booking, availableMethods) {
        if (booking && availableMethods) {
            const language = _t.getLocales()

            const preferredFlowTypesOrder = [
                PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED,
                PAYMENT_METHOD_FLOW_TYPE_SOFORT,
                PAYMENT_METHOD_FLOW_TYPE_PAYPAL,
                PAYMENT_METHOD_FLOW_TYPE_VOUCHER
            ]

            if (language === 'nl-NL') {
                // iDEAL should be displayed after credit card tabs
                preferredFlowTypesOrder.splice(2, 0, PAYMENT_METHOD_FLOW_TYPE_IDEAL)
            } else {
                preferredFlowTypesOrder.push(PAYMENT_METHOD_FLOW_TYPE_IDEAL)
            }

            // Filter methods based on payment provider but keep methods that don't have a provider (voucher and cash)
            const filteredMethods = availableMethods.filter(method => method.provider_code !== PAYMENT_PROVIDER_WORLDPAY)

            // Sort methods reverse alphabetically so CC3DS is in front on CC2DS
            let methodsSortedByCode = sortBy(filteredMethods, method => method.code.toLowerCase())

            if (language !== 'nl-NL') {
                methodsSortedByCode = reverse(methodsSortedByCode)
            }
            const methodsGroupedByFlowType = groupBy(methodsSortedByCode, method => method.flow_type)

            // add missing flow types to the preffered order list
            Object.keys(methodsGroupedByFlowType).forEach(flowType => {
                if (!preferredFlowTypesOrder.includes(flowType)) {
                    preferredFlowTypesOrder.push(flowType)
                }
            })

            const conditions = {
                isEuro: booking.currency === CURRENCY_EUR,
                isVoucherAvailable: this._isVoucherAvailable()
            }

            // create filtered methods list based of preffered order
            const methods = preferredFlowTypesOrder.reduce(
                (_methods, flowType) => _methods.concat(
                    filtersPerFlowType[flowType] ? filtersPerFlowType[flowType](
                        methodsGroupedByFlowType[flowType] || [],
                        conditions
                    ) : filtersPerFlowType[PAYMENT_METHOD_FLOW_TYPE_DEFAULT](methodsGroupedByFlowType[flowType] || [])
                ),
                []
            )

            this.setState(
                {
                    methods,
                    paymentOptions: this._createPaymentOptions(methods)
                },
                () => this._updateDefaultTab(methods)
            )
        }
    },

    _createPaymentOptions (methods) {
        return methods.map(method => {
            const [provider, ...rest] = method.code.split('_')
            const codeWithoutProvider = rest.length ? rest.join('_') : provider
            const text = method.description ||
              _t.safeFormatIntlMessage(`payment.method-names.${codeWithoutProvider.toLowerCase()}`) ||
              _t.formatIntlMessage('payment.method-names.other')

            return {
                key: method.code,
                label: text,
                title: text,
                text,
                value: method.code
            }
        })
    },

    _updateDefaultTab (methods) {
        if (this.state.disabled && this._getSelectedMethodCode() !== null) {
            this.handleSelectMethod(null)
            return
        }

        let defaultMethod
        const booking = this.state.booking
        if (!booking.requiresPayment && booking.vouchers.length > 0 && this._isVoucherAvailable()) {
            defaultMethod = {code: PAYMENT_METHOD_CODE_VOUCHER}
        }

        if (!defaultMethod && methods && methods.length) {
            const selectedMethod = this._getSelectedMethodCode()
            if (!selectedMethod || !methods.some(method => method.code === selectedMethod)) {
                const findMethod = flowType => methods.find(method => method.flow_type === flowType)
                defaultMethod = findMethod(PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED) || methods[0]
                defaultMethod = (_t.getLocales() === 'nl-NL' && findMethod(PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED)) || defaultMethod
                defaultMethod = (_t.getLocales() === 'nl-BE' && findMethod(PAYMENT_METHOD_FLOW_TYPE_BANCONTACT)) || defaultMethod
            }
        }

        defaultMethod && this.handleSelectMethod(defaultMethod.code)
    },

    _getSelectedMethodCode () {
        return this.state.payment.paymentMethodCode
    },

    _isVoucherAvailable () {
        return AccessManager.hasAccessToVoucherPayment()
    },

    _selectFieldProps (fieldName, options) {
        let kebabCaseFieldName = kebabCase(fieldName)
        return {
            id: fieldName,
            ref: fieldName,
            className: kebabCaseFieldName,
            data: options,
            value: this.state.payment.paymentMethodCode,
            labelText: '',
            required: true,
            onChange: this.handleSelectMethod
        }
    },

    handleSelectMethod (methodCode) {
        const paymentMode = this.state.availableMethods.find(method => method.code === methodCode)

        if (paymentMode && (paymentMode.flow_type === PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED || paymentMode.flow_type === PAYMENT_METHOD_FLOW_TYPE_IDEAL) &&
          paymentMode.provider_code === PAYMENT_PROVIDER_ADYEN &&
          paymentMode.method_code
        ) {
            actions.getPaymentCardBrands(paymentMode.method_code, paymentMode.provider_code)
        }

        actions.processPaymentData([{
            id: 'method',
            value: methodCode
        }, {
            id: 'agree',
            value: true
        }])
    },

    _renderPaymentMethods () {
        const code = this._getSelectedMethodCode()

        return this.state.paymentOptions ? (
            <div className={!device.isMobile() ? 'tab-container' : null}>
                {device.isMobile()
                    ? <Select {...this._selectFieldProps('method', this.state.paymentOptions)} />
                    : (
                        <Tabs
                            key={_t.getLocales()}
                            goto={this.handleSelectMethod}
                            current={code}
                            tabs={this.state.paymentOptions}
                        />
                    )
                }
                {this._renderPaymentTab(code)}
            </div>
        ) : null
    },

    _renderPaymentTab () {
        const paymentCardBrands = PaymentMethodsStore.getPaymentCardBrands()
        switch (this.state.payment.paymentForm) {
            case PAYMENT_FORM_VOUCHER:
                return <Voucher />
            case PAYMENT_FORM_WPAY_CREDITCARD_ENCRYPTED:
                return (
                    <CreditCard
                        formData={this.state.payment}
                        customerName={this.state.booking.customerName}
                    />
                )
            case PAYMENT_FORM_ADYEN_CREDITCARD_ENCRYPTED:
                return (
                    <CreditCardAdyen
                        key={this.state.payment.paymentMethodCode}
                        dataLoading={this.state.paymentMethodsLoading}
                        clientKey={this.state.payment.paymentMethodPublicKey}
                        paymentMethodCode={this.state.payment.paymentMethodCode}
                        availableCardBrands={paymentCardBrands}
                    />
                )
            case PAYMENT_FORM_ADYEN_IDEAL:
                return (
                    <Ideal
                        formData={this.state.payment}
                        code={this._getSelectedMethodCode()}
                    />
                )
            case PAYMENT_FORM_CASH:
                return (
                    <CashInvoice
                        formData={this.state.payment}
                        paymentAmount={this.state.booking.pendingTotalPriceToBePaid}
                    />
                )
            case PAYMENT_FORM_ADYEN_PAYPAL:
            case PAYMENT_FORM_ADYEN_SOFORT:
            case PAYMENT_FORM_WPAY_BANCONTACT:
                return <Redirect code={this._getSelectedMethodCode()} />
            default:
                return null
        }
    },

    render () {
        const booking = this.state.booking
        if (!booking || !this.state.payment || !this.state.methods) {
            return null
        }

        return (
            <div className='payment-options'>
                <div className='panel'>
                    <header>
                        <h2>{_t.getIntlMessage('payment.options.header')}</h2>
                    </header>
                    <div className='content'>
                        {!booking.requiresPayment && booking.vouchers.length > 0 && !AccessManager.isCrmUser()
                            ? <CmsBlockContent name='payment-by-voucher' />
                            : this._renderPaymentMethods()
                        }
                    </div>
                </div>
            </div>
        )
    }
})
