/* globals S3P_SETTINGS: true */

import React, {Component} from 'react'
import _t from '../../../../translate'
import TabContent from '../../../../element/tab-content'
import actions from '../../../../reflux/actions'
import PaymentTerms from '../payment-terms'
import AdyenCheckout from '@adyen/adyen-web'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import Loader from '../../../../element/loader'

const adyenFormStyle = `
        .adyen-checkout__helper-text, .adyen-checkout__label__text {
            color: #001b2b;
            display: block;
            font-size: 13px;
            font-weight: 400;
            line-height: 13px;
            padding-bottom: 8px;
        }
        .adyen-checkout__card__form {
          margin-top: 0.5rem;
          margin-bottom: 2rem;
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr;
          min-width: 18rem;
          max-width: 24rem;
        }
        .adyen-checkout__field {
          height: 5rem;
        }
        .adyen-checkout__input-wrapper {
            border-color: #b9c4c9;
            border-width: 1px;
            border-style: solid;
            border-radius: 3px;
            height: 3rem;
            display: grid;
            grid-template-columns: 1fr auto;
        }
        .adyen-checkout__field--error > .adyen-checkout__input-wrapper {
            border-color: red;
            grid-template-columns: 2fr auto;
        }
        .adyen-checkout__card__cardNumber__input.adyen-checkout__input--error {
            display: grid;
            grid-template-columns: auto 1fr auto;
        }
        .adyen-checkout__card__exp-cvc {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
        }
        .adyen-checkout-input__inline-validation {
            margin-top: 1rem;
            margin-right: 0.5rem;
        }
        .adyen-checkout__card__cardNumber__brandIcon {
            width: 2.5rem;
            height: 1.5rem;
            margin: 0.75rem;
        }
        .adyen-checkout__card__exp-cvc .adyen-checkout__input-wrapper {
            display: grid;
            grid-template-columns: 1fr auto auto;
            padding-left: 0.75rem;
            padding-right: 1.5rem;
        }
        .adyen-checkout__card__exp-cvc > .adyen-checkout__field--error > .adyen-checkout__input-wrapper ,
        .adyen-checkout__card__exp-cvc > .adyen-checkout__field--valid > .adyen-checkout__input-wrapper {
            grid-template-columns: 1fr auto auto;
            padding-right: 0rem;
        }
        .adyen-checkout__field__exp-date_hint {
            height: 1.5rem;
            margin: 0.75rem;
        }
        .adyen-checkout__field__cvc--back-hint {
            margin: 1rem;
        }
        .adyen-checkout__field__cvc--back-hint > .adyen-checkout__card__cvc__hint--back {
            margin-left: 5px;
        }
        .adyen-checkout__input > .js-iframe {
            height: 3rem !important;
            border: none !important;
            max-width: auto !important;
        }
        .adyen-checkout__input-wrapper {
            padding-left: 0.75rem;
        }
        .adyen-checkout__field--error > .adyen-checkout__input-wrapper {
            border-color: #d0021b;
        }
        .adyen-checkout__error-text {
            line-height: 1.5rem;
            color: #d0021b;
            font-size: 0.75rem;
        }
        .adyen-checkout__field--valid > .adyen-checkout__input-wrapper {
            border-color: #04ba65;
        }
        .sr-panel-holder {
            position: absolute;
            bottom: 0;
        }
        .adyen-checkout__card__brands {
           display: none;
        }
        .adyen-checkout__card__dual-branding__buttons--active > .adyen-checkout__card__cardNumber__brandIcon {
          border: 1px solid green;
          cursor: pointer;
        }  
        .adyen-checkout__card__dual-branding__buttons--active > .adyen-checkout__card__cardNumber__brandIcon--not-selected {
          border: 0px solid green;
        }  
      `

const createAdyenCardCheckout = async ({clientKey, environment, locale, onChange, onError, availableCardBrands}, {onConfigSuccess}) => {
    const adyenConfig = {
        environment,
        clientKey,
        analytics: {
            enabled: false
        },
        locale,
        onError,
        onChange
    }
    adyenConfig.paymentMethodsConfiguration = {
        card: {
            styles: {
                base: {
                    fontSize: '14px'
                }
            }
        }
    }
    if (availableCardBrands && availableCardBrands.length) {
        adyenConfig.paymentMethodsConfiguration.card.brands = availableCardBrands
    }

    const checkout = await AdyenCheckout(adyenConfig)
    return checkout.create('card', {onConfigSuccess})
}

const CARD_CONTAINER_PREFIX = 'card-container-'

export default class FormCreditCardAdyen extends Component {
    static propTypes = {
        dataLoading: PropTypes.bool.isRequired,
        clientKey: PropTypes.string.isRequired,
        paymentMethodCode: PropTypes.string.isRequired,
        availableCardBrands: PropTypes.arrayOf(PropTypes.string)
    }

    constructor (...args) {
        super(...args)

        this.state = {
            cardContainerId: `${CARD_CONTAINER_PREFIX}${this.props.paymentMethodCode}`
        }

        this.adyenInstance = null
        this.isCardContainerLoading = true

        this._initializeAdyenComponent = this._initializeAdyenComponent.bind(this)
        this._mountCardContainer = this._mountCardContainer.bind(this)
        this._unmountCardContainer = this._unmountCardContainer.bind(this)
    }

    componentDidMount () {
        if (this.props.dataLoading) return
        this._initializeAdyenComponent()
    }

    componentDidUpdate (prevProps) {
        if (this.props.dataLoading) return
        if (this.props.paymentMethodCode !== prevProps.paymentMethodCode) {
            this.setState({
                cardContainerId: `${CARD_CONTAINER_PREFIX}${this.props.paymentMethodCode}`
            })
        }
        if (
            this.props.dataLoading !== prevProps.dataLoading ||
            this.props.paymentMethodCode !== prevProps.paymentMethodCode ||
            this.props.clientKey !== prevProps.clientKey ||
            !isEqual(this.props.availableCardBrands, prevProps.availableCardBrands)
        ) {
            this._initializeAdyenComponent()
        }
    }

    componentWillUnmount () {
        this._unmountCardContainer()
    }

    _initializeAdyenComponent () {
        this._unmountCardContainer()

        createAdyenCardCheckout(
            {
                environment: S3P_SETTINGS.adyen.environment ?? 'test',
                clientKey: this.props.clientKey,
                locale: _t.getLocales(),
                onChange: state => {
                    if (state.isValid) {
                        const postPaymentRequestData = {
                            encrypted_card_number:
                            state.data.paymentMethod.encryptedCardNumber,
                            encrypted_expiry_month:
                            state.data.paymentMethod.encryptedExpiryMonth,
                            encrypted_expiry_year:
                            state.data.paymentMethod.encryptedExpiryYear,
                            encrypted_security_code:
                            state.data.paymentMethod.encryptedSecurityCode
                        }
                        if (state.data.paymentMethod.brand) {
                            postPaymentRequestData.psp_payment_details = {
                                card_brand: state.data.paymentMethod.brand
                            }
                        }
                        actions.processPaymentData([
                            {id: 'data', value: postPaymentRequestData}
                        ])
                    }
                },
                onError: error => {
                    console.error(error)
                    this.isCardContainerLoading = false
                },
                availableCardBrands: this.props.availableCardBrands
            },
            {
                onConfigSuccess: () => { this.isCardContainerLoading = false }
            }
        ).then(instance => {
            this.adyenInstance = instance
            this._mountCardContainer()
        }).catch(() => {
            this.isCardContainerLoading = false
        })
    }

    _mountCardContainer () {
        if (this.adyenInstance) {
            this.adyenInstance.mount(`#${this.state.cardContainerId}`)
        }
    }

    _unmountCardContainer () {
        if (!this.isCardContainerLoading && this.adyenInstance) {
            this.adyenInstance.unmount()
            this.adyenInstance = null
        }
    }

    render () {
        if (this.props.dataLoading) {
            return <div className='credit-card-container loader'><Loader /></div>
        }
        if (!this.props.availableCardBrands) {
            return null
        }
        return (
            <TabContent visible>
                <style type='text/css'>{adyenFormStyle}</style>
                <div className='credit-card-container adyen'>
                    <div id={this.state.cardContainerId} />
                    <PaymentTerms />
                </div>
            </TabContent>
        )
    }
}
