import React from 'react'
import {Route} from 'react-router'
import Index from './routes/index-route'
import AgentLogin from './routes/agent-login'
import CrmLogin from './routes/crm-login'
import CrmRegister from './routes/crm-register'
import MyS3Bookings from './routes/mys3/bookings'
import MyS3Booking from './routes/mys3/booking'
import BookingLogin from './routes/booking-login'
import BookingPassengerDetails from './routes/booking/passenger-details'
import BookingOptions from './routes/booking/options'
import BookingCustomerDetails from './routes/booking/customer-details'
import BookingPayment from './routes/booking/payment'
import BookingConfirmation from './routes/booking/confirmation'
import BookingSeatSelector from './routes/booking/seat-selector'
import BookingLuggageSelector from './routes/booking/luggage-selector'
import BookingBikeSelector from './routes/booking/bike-selector'
import OrientationOffer from './routes/orientation/offer'
import OrientationDeepLink from './routes/orientation/deep-link'
import Cancellation from './routes/mys3/cancellation'
import CancellationConfirm from './routes/mys3/cancellation/confirm'
import CancellationConfirmation from './routes/mys3/cancellation/confirmation'
import MyOptions from './routes/mys3/options'
import SeatViewSeat from './routes/mys3/seat/view-seats'
import SeatEditSeat from './routes/mys3/seat/edit-seats'
import SeatSelector from './routes/mys3/seat/seat-selector'
import SeatPayment from './routes/mys3/seat/payment'
import SeatConfirm from './routes/mys3/seat/confirm'
import SeatConfirmation from './routes/mys3/seat/confirmation'
import LuggageSelector from './routes/mys3/luggage/luggage-selector'
import LuggagePayment from './routes/mys3/luggage/payment'
import LuggageConfirmation from './routes/mys3/luggage/confirm'
import BikeSelector from './routes/mys3/bike/bike-selector'
import BikePayment from './routes/mys3/bike/payment'
import BikeConfirmation from './routes/mys3/bike/confirm'
import UpdatePassengers from './routes/mys3/update-passengers'
import UpdatePassengersPayment from './routes/mys3/update-passengers/payment'
import UpdatePassengersConfirm from './routes/mys3/update-passengers/confirm'
import UpdatePassengersConfirmation from './routes/mys3/update-passengers/confirmation'
import RebookingOffer from './routes/mys3/re-booking/offer'
import RebookingDetails from './routes/mys3/re-booking/details'
import RebookingOptions from './routes/mys3/re-booking/options'
import RebookingSeatSelector from './routes/mys3/re-booking/seat-selector'
import RebookingLuggage from './routes/mys3/re-booking/luggage'
import RebookingBike from './routes/mys3/re-booking/bike'
import RebookingPayment from './routes/mys3/re-booking/payment'
import RebookingConfirm from './routes/mys3/re-booking/confirm'
import RebookingConfirmation from './routes/mys3/re-booking/confirmation'
import RequestJsonApiKey from './routes/request-json-api-key'
import Reach5Callback from './routes/reach5-callback'

import SystemUnavailable from './routes/system-unavailable'
import BookingNotFound from './routes/error/booking-not-found'
import Error from './routes/error'

const childRoutes = [
    AgentLogin,
    BookingLogin,
    CrmLogin,
    CrmRegister,
    RequestJsonApiKey,
    /* Orientation routes */
    OrientationOffer,
    OrientationDeepLink,
    /* Booking routes */
    BookingPassengerDetails,
    BookingOptions,
    BookingCustomerDetails,
    BookingPayment,
    BookingConfirmation,
    BookingSeatSelector,
    BookingLuggageSelector,
    BookingBikeSelector,
    /* My S3 routes */
    MyS3Bookings,
    MyS3Booking,
    /* Re-book routes */
    RebookingOffer,
    RebookingDetails,
    RebookingOptions,
    RebookingSeatSelector,
    RebookingLuggage,
    RebookingBike,
    RebookingPayment,
    RebookingConfirm,
    RebookingConfirmation,
    /* Update passengers routes */
    UpdatePassengers,
    UpdatePassengersPayment,
    UpdatePassengersConfirm,
    UpdatePassengersConfirmation,
    /* Cancellation routes */
    Cancellation,
    CancellationConfirm,
    CancellationConfirmation,
    /* View options */
    MyOptions,
    /* View seats */
    SeatViewSeat,
    SeatEditSeat,
    SeatSelector,
    SeatPayment,
    SeatConfirm,
    SeatConfirmation,
    /* Luggage */
    LuggageSelector,
    LuggagePayment,
    LuggageConfirmation,
    /* Bike */
    BikeSelector,
    BikePayment,
    BikeConfirmation,
    /* General routes */
    Reach5Callback,
    BookingNotFound,
    Error,
    SystemUnavailable
]

const Routes = App => (
    <Route component={App} path='/' getChildRoutes={(_, callback) => { callback(null, childRoutes) }}>
        {Index}
    </Route>
)

export default Routes
